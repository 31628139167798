import { ActionReducerMap } from '@ngrx/store';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { faetureOneKey } from '@poc-monorepo/featureone';
import { AppState, appStateKey, appStateReducer } from '@poc-monorepo/shared-state';




type mainAppType = Omit<AppState, typeof faetureOneKey>

export const appReducers: ActionReducerMap<mainAppType> = {

  [appStateKey]: appStateReducer,
}
