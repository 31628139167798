import { SeItem } from '@poc-monorepo/shared';

export const routes: SeItem[] = [
  {
    id: 'dashboard',
    code: '001',
    title: 'แดชบอร์ด',
    type: 'basic',
    icon: 'dashboard',
    link: '/app/fo',
    children: [],
  },
  {
    id: 'plan',
    code: '002',
    title: 'แผนงาน',
    type: 'collapsable',
    icon: 'work_history',
    children: [
      {
        id: 'set-plan',
        code: '002-000',
        title: 'กำหนดแผนงาน',
        type: 'basic',
        // icon: 'work_history',
        link: '/app/set-plan',
        children: [],
      },
    ],
  },
  {
    id: 'profile',
    code: '002',
    title: 'ติดตามผลการจดมาตร',
    type: 'collapsable',
    ischildActive: false,
    icon: 'gas_meter',
    role: ['01'],
    children: [
      {
        id: 'report1-1',
        code: '002-004-001',
        title: 'ตรวจสอบข้อมูล',
        type: 'basic',
        // icon: 'fiber_manual_record',
        link: '/app/gun',
        ischildActive: false,
      },
      {
        id: 'report1',
        code: '002-004',
        title: 'ประวัติการจดมาตร',
        type: 'collapsable',
        icon: 'history',
        ischildActive: false,
        role: ['01-01'],
        children: [
          {
            id: 'report1-1',
            code: '002-004-001',
            title: 'ตรวจสอบประวัติ',
            type: 'basic',
            // icon: 'fiber_manual_record',
            link: '/app/example',
            ischildActive: false,
            role: ['01-01-01'],
            children: [],
          },
          {
            id: 'report1-1',
            code: '002-004-001',
            title: 'ประวัติการจดมาตร',
            type: 'basic',
            // icon: 'fiber_manual_record',
            link: '/app/example',
            ischildActive: false,
            role: ['01-01-01'],
            children: [],
          },
        ],
      },
    ],
  },
  {
    id: 'setting',
    code: '007',
    title: 'การตั้งค่า',
    type: 'collapsable',
    icon: 'settings',
    children: [
      {
        id: 'contract',
        code: '007',
        title: 'สัญญาผู้รับเหมา',
        type: 'basic',
        // icon: 'work_history',
        link: '/app/plan',
        children: [],
      },
    ],
  },
];
