<!-- <div class="flex flex-col items-center md:rounded-2xl  justify-between w-full " style="background-color: #355ba6;">
    <div id="toolbar"  class="relative flex items-center w-full h-16  z-49">
        <div class="flex items-center justify-between w-full">
            <div class="flex flex-row">
                <div class="dropdown">
                    <div tabindex="0" role="button" class="btn btn-ghost m-1"> <mat-icon style="color: white;">menu</mat-icon></div>
                    <ul tabindex="0" class="dropdown-content menu text-black bg-white rounded-box z-[1] w-52 p-2 shadow">
                        <li><a>Item 1</a></li>
                        <li><a>Item 2</a></li>
                      </ul>
                  </div>
                <div class="flex items-center">
                    <span class="text-2xl" style="color: white">ระบบบริหารจัดการ OCR</span>
                </div>
                   
            </div>
              <div class="flex flex-row">
                  <div class="flex items-center">
                  <span style="color: white"></span>
              </div>
              <div>
                <button (click)="logout()"
                class="btn btn-ghost"
                mat-icon-button
                style="color: white;"
                >
                <mat-icon style="color: white;">logout</mat-icon>
            </button>
              </div>
            </div>
        </div>
    </div>
</div> -->

<div
  class="relative drawer flex flex-col items-center md:rounded-2xl justify-between w-full"
  style="background-color: #355ba6;"
>
  <div id="toolbar" class="z-50 flex items-center w-full h-16 z-49">
    <div class="flex items-center justify-between w-full">
      <div class="flex flex-row">
        <input id="my-drawer" type="checkbox" class="drawer-toggle" />
        <div class="drawer-content">
          <label for="my-drawer" class="btn btn-ghost m-1"><mat-icon style="color: white;">menu</mat-icon></label>
        </div>
        <div class="flex items-center">
          <span class="text-2xl" style="color: white;"
            >ระบบบริหารจัดการ OCR</span
          >
        </div>
        <div class="drawer-side">
            <label
              for="my-drawer"
              aria-label="close sidebar"
              class="drawer-overlay"
              
            ></label>
            <ul style="background-color: #355ba6;" class="menu text-base-content min-h-full">
                <lib-menu-bar [menus]="menus"></lib-menu-bar>
              <!-- <li><a>Sidebar Item 1</a></li>
              <li><a>Sidebar Item 2</a></li> -->
            </ul>
          </div>
      </div>
      <div class="flex flex-row">
        <div class="flex items-center">
          <span style="color: white;"></span>
        </div>
        <div>
          <button
            (click)="logout()"
            class="btn btn-ghost"
            mat-icon-button
            style="color: white;"
          >
            <mat-icon style="color: white;">logout</mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
