import { Component, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { LOAD, selectNo } from '@poc-monorepo/shared-state';



@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterModule
  ],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent {
  title = 'main-app';
  // store = inject(Store)
  // no$ = inject(Store).select(selectNo)
  constructor() {
    // this.sv.get(environment.baseapi).subscribe()
  }


  // buttonState() {
  //   this.store.dispatch(LOAD())
  // }

}
