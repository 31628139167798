<!-- <div class="se_item_warpper">
    <a class="se_item text-white hover:cursor-pointer"
            *ngIf="item?.link && !item?.externalLink && !item?.function && !item?.disabled"
            [ngClass]="{'fuse-vertical-navigation-item-active-forced': item?.active}"
            [routerLink]="[item?.link]"
            [routerLinkActive]="'se_item_active'">
        {{ item?.title }}
    </a>
</div> -->

<li class="text-white ">
    <a
    class="h-10 content-center" [ngClass]="!hide ? '' : 'self-center rounded-full'"
      *ngIf="item?.link && !item?.externalLink && !item?.function && !item?.disabled"
      [routerLink]="[item?.link]"
      [routerLinkActive]="'se_item_active'"
      >
      @if(item?.icon){
        <mat-icon class="h-5 w-5">
            {{ item?.icon }}
        </mat-icon>
      } 
      @if (!hide) {
          {{item?.title}}
      }
      </a
    >
  </li>
