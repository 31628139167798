import { CommonModule } from '@angular/common';
import { ToolBarComponent } from '../components/tool-bar/tool-bar.component';
import { MenuBarComponent } from './../components/menu-bar/menu-bar.component';
import { Component, ChangeDetectionStrategy, Input, OnInit, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterModule } from '@angular/router';
import { SeItem } from '../../../../core/data/type';
import { filter, map, startWith, switchMap, tap } from 'rxjs';

@Component({
  selector: 'lib-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  standalone: true,
  imports: [
    MenuBarComponent,
    ToolBarComponent,
    CommonModule,
    RouterModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainLayoutComponent {
  @Input() menu: SeItem[] = []
  @Input() header: any;
  router = inject(Router)
  activeRoute = inject(ActivatedRoute)

  breawdcum$ = this.router.events.pipe(
    filter(r => r instanceof NavigationEnd),
    startWith(this.router),
    switchMap(() => {
      const lastPath = this.router.url.split("/").pop()

      const activeChildRoute: any = this.activeRoute.snapshot.firstChild?.children[0].routeConfig?.children?.find(r => r.path == lastPath)

      return this.activeRoute.children[0]?.data.pipe(
        map((data: any) => {
          return [
            data.header,
            activeChildRoute && activeChildRoute?.data?.header
          ].filter(f => f).join(" > ")
        })
      )
    })
  )

  isExpanedMenu = false
  // constructor() { }

}
