<!-- <div class="se_item_warpper">
  <div (click)="toggleCollapsable()" [ngClass]=" item?.ischildActive  ? 'child_active' : '' ">
    <a class="se_item text-white hover:cursor-pointer">
      {{ item?.title }}
      <mat-icon class="se_icon" [ngStyle]="{'transform': isCollapsed ? '': 'rotate(360deg)'}">expand_more</mat-icon>
    </a>
  </div>

  <div class="se_item_children" *ngIf="!isCollapsed" @expandCollapse>
    <ng-container *ngFor="let menuChilden of item?.children; trackBy: identify">
      <ng-container *ngIf="menuChilden.type === 'basic'">
        <lib-basic-menu [item]="menuChilden"></lib-basic-menu>
      </ng-container>
      <ng-container *ngIf="menuChilden.type === 'collapsable'">
        <lib-collapsable [item]="menuChilden"></lib-collapsable>
      </ng-container>
    </ng-container>
  </div>

</div> -->

<li class="text-white">
  <details class="" [open]="isCollapsed">
    <summary class="h-10" (click)="toggleCollapsable()" (click)="hideChange()">
      <mat-icon class="h-5 w-5">
        {{ item?.icon}}
      </mat-icon>
      @if (!hide) { {{item?.title}} }
    </summary>
   
    @if (!hide) {
    <ul class="menu  gap-2">
      <ng-container
        *ngFor="let menuChilden of item?.children; trackBy: identify"
      >  
        <ng-container *ngIf="menuChilden.type === 'basic'">
          <lib-basic-menu [item]="menuChilden" [hide]="hide"></lib-basic-menu>
        </ng-container>
        <ng-container *ngIf="menuChilden.type === 'collapsable'">
          <lib-collapsable [item]="menuChilden" [hide]="hide"></lib-collapsable>
        </ng-container>
      </ng-container>
    </ul>
    }
    @else{
    <div class="pb-1" >
      <ng-container
        *ngFor="let menuChilden of item?.children; trackBy: identify"
      >
        <!-- <ng-container *ngIf="menuChilden.type === 'basic'">
          <lib-basic-menu [item]="menuChilden" [hide]="hide"></lib-basic-menu>
        </ng-container> -->
        <!-- <ng-container *ngIf="menuChilden.type === 'collapsable'">
          <lib-collapsable [item]="menuChilden" [hide]="hide"></lib-collapsable>
        </ng-container> -->
      </ng-container>
    </div>
    }
    <!-- <ul class="gap-2">
      <ng-container
        *ngFor="let menuChilden of item.children; trackBy: identify"
      >
        <ng-container *ngIf="menuChilden.type == 'basic'">
          <app-basic-menu [item]="menuChilden" [hide]="hide"></app-basic-menu>
        </ng-container>
        <ng-container *ngIf="menuChilden.type == 'collapsable'">
          <app-collapsable [item]="menuChilden" [hide]="hide"></app-collapsable>
        </ng-container>
      </ng-container>
    </ul> -->
  </details>
</li>