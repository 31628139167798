import { createReducer, on } from "@ngrx/store"
import { LOAD } from "../action/app-state.action"


export const appStateKey = 'appState'

export interface WaterState {
  no: number
}

export const inintState: WaterState = {
  no: 0
}

export const _appstateReducer = createReducer(
  inintState,
  on(LOAD, (state, action) => ({
    ...state,
    no: state.no + 1
  }))
)

export function appStateReducer(action: any, state: any) {
  return _appstateReducer(action, state)
}
