<div class="relative z-0 flex flex-col menuSetting ease-in-out transition-all duration-300 h-[98vh] overflow-y-auto "  [ngClass]="isHide ? 'min-w-fit':'min-w-[280px] min-h-[280px]'">
  @if(!isHide){
    <div class="h-[31px]  pr-2 py-3 origin-top-left items-center flex flex-row justify-end gap-2.5 ">
      <div class="w-9 pt-5 ">
        <!-- <button class="block xl:hidden btn btn-ghost btn-circle btn-sm">
          <mat-icon class="text-white" >sort</mat-icon>
        </button> -->
        <button class="hidden xl:block btn btn-ghost btn-circle btn-sm">
          <mat-icon class="text-white" (click)="isHide = !isHide" >menu</mat-icon>
        </button>
  
        <!-- <mat-icon class="text-white hover:bac" (click)="isMobile = !isMobile" >menu </mat-icon> -->
      </div>
    </div>
  }
    <div class="flex flex-row items-center self-center p-3 hover:scale-105 transition-all  duration-200 ease-in-out" (click)="isHide = !isHide" >
        <picture class="rounded-full">
<!--            <span>iamge here</span>-->
<!--             <source  type="image/webp" srcset="assets/images/logo/logo.webp">-->
            <!-- <source type="image/jpeg" srcset="assets/logo/img.png" class="w-[190px] h-[191px]"> -->
            <img src="assets/image/logo/logo.png" [ngClass]="isHide ? 'w-[73px]':'w-[190px] h-[191px]'"
            class="rounded-full transition-all ease-in-out duration-300">
        </picture>
    </div>
      <!-- menu here -->
      <ul class="menu rounded-full w-full gap-2">
        <ng-container *ngFor="let menu of menus">
          <ng-container *ngIf="menu?.type === 'basic'">
            <lib-basic-menu [item]="menu" [hide]="isHide"></lib-basic-menu>
          </ng-container>
          <ng-container *ngIf="menu?.type === 'collapsable' ">
            <lib-collapsable [item]="menu" [hide]="isHide" (changeHide)="hideChange()"></lib-collapsable>
          </ng-container>
        </ng-container>
      </ul>
      <!-- menu here -->
</div>

