<div class="relative w-full h-full" style="background-color: #edededea;">
  <div
    class="flex flex-col min-h-full ease-in-out transition-all duration-300 sm:p-3"
  >
    <div class="relative flex flex-row w-full gap-3.5">
      <div
        class=" z-40 hidden xl:block transition-all duration-500 ease-in-out xl:translate-x-0 xl:opacity-100"
      >
        <lib-menu-bar [menus]="menu"></lib-menu-bar>
      </div>
      <!--        <div class="flex flex-col w-full h-full" id="main_container">-->
      <!--            <app-tool-bar (expanedMenu)="isExpanedMenu = $event"></app-tool-bar>-->
      <!--            <div class="p-4 m-4 shadow-lg">-->
      <!--                <router-outlet *ngIf="true"></router-outlet>-->
      <!--            </div>-->
      <!--        </div>-->
      <div class="z-0 flex flex-col w-full h-fit md:gap-2">
        <div class="block xl:hidden">
          <lib-tool-bar [menus]="menu"></lib-tool-bar>
        </div>
        <!-- <lib-tool-bar [menus]="menu"></lib-tool-bar> -->

        {{ breawdcum$ | async }}
        <!-- <div class="breadcrumbs text-sm p-0">
          <ul>
            <li><a>Home</a></li>
            <li><a>Documents</a></li>
            <li>Add Document</li>
          </ul>
        </div> -->
        <div class="w-full h-full md:rounded-xl bg-white md:bg-transparent">
          <!-- Content is Here -->
          <router-outlet class="" *ngIf="true"></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
